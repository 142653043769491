/* UsersTable.css */
.container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 16px;
  }
  
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .header h2 {
    font-size: 24px;
    font-weight: bold;
    color: #545454;
  }
  
  .filter-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .filter-button {
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: #e0e0e0;
  }
  
  .filter-button.all.active,
  .filter-button.all:hover {
    background-color: #545454;
    color: white;
  }
  
  .filter-button.fullyPaid.active,
  .filter-button.fullyPaid:hover {
    background-color: #FF5757;
    color: white;
  }
  
  .filter-button.partiallyPaid.active,
  .filter-button.partiallyPaid:hover {
    background-color: #ffc107;
    color: white;
  }
  
  .user-box-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .user-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    border: 2px solid #FF5757;
    border-radius: 40px;
    padding: 16px;
    margin-bottom: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    width: calc(33.33% - 50px); /* Adjusted to fit three boxes per row */
    justify-content: space-between;
    height: 200px; /* Set a fixed height for the user-box */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Show ellipsis for overflowing text */
  }
  
  .user-box:hover {
    transform: scale(1.02);
  }
  
  .user-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
  }
  
  .user-details {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex-grow: 1;
    color: #545454;
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Show ellipsis for overflowing text */
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .user-details span {
    display: block;
    margin-bottom: 4px;
  }
  
  .user-details span.label {
    font-weight: bold;
    color: #545454;
  }
  
  /* Responsive styles */
  @media (max-width: 1024px) {
    .user-box {
      width: calc(50% - 32px); /* Two columns on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  
    .filter-buttons {
      width: 100%;
      justify-content: center;
    }
  
    .filter-button {
      flex: 1;
      text-align: center;
    }
  
    .user-box {
      width: calc(100% - 50px); /* One column on small screens */
      flex-direction: column;
      align-items: flex-start;
      padding: 8px;
      height: 50px; /* Adjust height for small screens */
    }
  
    .user-image {
      margin-bottom: 8px;
    }
  
    .user-details {
      flex-direction: column;
      align-items: flex-start;
      white-space: normal; /* Allow text wrapping */
    }
  }
  
  @media (max-width: 480px) {
    .user-box {
      width: calc(100% - 50px);
      padding: 8px; /* Reduce padding for very small screens */
      height: auto; /* Adjust height for very small screens */
    }
  
    .user-image {
      width: 50px; /* Reduce image size for very small screens */
      height: 50px;
    }
  }
  
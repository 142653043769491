.container {
  width: 100%;
  max-width: 1200px; /* Adjust the max-width to match your dashboard width */
  height: 80vh; /* Full viewport height */
  background-color: white; /* White background */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.banner {
  display: flex;
  align-items: center;
  background-color: #FF5757; /* Banner background color */
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

h1 {
  margin: 0;
}

.content {
  margin-top: 20px;
}

.uploadSection, .manualSection {
  background-color: #fff; /* Section background color */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropzone {
  border: 2px dashed #FF5757;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.button {
  background-color: #FF5757;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.button:hover {
  background-color: #e04d4d;
}

.add-button {
  background-color: #FF5757;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 10px;
}

.add-button:hover {
  background-color: #e04d4d;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.footer {
  text-align: center;
  margin-top: 20px;
}
